import React from 'react';
import { hydrate, render } from "react-dom";
import reportWebVitals from './reportWebVitals';
import { RouterProvider} from "react-router-dom";
import router from "./router";
import './index.css';

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
    hydrate(<RouterProvider router={router}/>, rootElement);
} else {
    render(<RouterProvider router={router}/>, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();